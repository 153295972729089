import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import AuthCallback from "./pages/Auth-Callback/AuthCallback";
import NotFound from "./pages/NotFound/NotFound";
import { AuthenticationGuard } from "./authentication-guard";
import { Navbar } from "./components/navbar/navbar";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/waitlist-success"
          element={<AuthenticationGuard component={AuthCallback} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
