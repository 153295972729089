import React, { useEffect } from "react";
import AOS from "aos";

function Pricing() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);
  return (
    <div className="mt-16 sm:mt-24 xl2:mt-36 flex flex-col items-center cursor-default">
      <div data-aos="zoom-in">
        <h2 className="text-4xl sm:text-6xl font-bold mb-4 text-white">
          Paketlerimiz
        </h2>
        <p className="mb-12 sm:text-lg text-white">
          Şu anda ödeme yapmanız gerekmemektedir. İndirimlerden faydalanmak için
          sadece kaydolmanız yeterlidir. Ürün piyasaya sunulduğunda, sizinle
          iletişime geçeceğiz ve paketlerinizi o zaman seçebilirsiniz. Bekleme
          listesi döneminde katıldığınız için indirim uygulanacaktır.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-6xl">
        <div data-aos="zoom-in">
          <div className="border-conic-gradient p-6 text-center shadow-lg hover:bg-gray-800 hover:translate-y-[-10px] transition-transform duration-300">
            <h3 className="text-2xl font-semibold text-primary mb-4">3 Gün</h3>
            <p className="text-xl text-gray-400 line-through">1000 TL</p>
            <p className="text-3xl font-bold text-white">500 TL</p>
            <p className="text-sm text-gray-400 mb-4">
              Lansmana Özel %50 İndirim
            </p>
            {/*<button className="bg-primary text-black font-medium py-2 px-6 rounded-lg hover:bg-secondary transition duration-300 ease-in-out">*/}
            {/*  Satın Al*/}
            {/*</button>*/}
          </div>
        </div>
        <div data-aos="zoom-in">
          <div className="border-conic-gradient p-6 text-center shadow-lg bg-gray-900 hover:bg-gray-800 hover:translate-y-[-10px] transition-transform duration-300">
            <h3 className="text-2xl font-semibold text-primary mb-4">
              Haftalık
            </h3>
            <p className="text-xl text-gray-400 line-through">2000 TL</p>
            <p className="text-3xl font-bold text-white">1000 TL</p>
            <p className="text-sm text-gray-400 mb-4">
              Lansmana Özel %50 İndirim
            </p>
            {/*<button className="bg-primary text-black font-medium py-2 px-6 rounded-lg hover:bg-secondary transition duration-300 ease-in-out">*/}
            {/*  Satın Al*/}
            {/*</button>*/}
          </div>
        </div>
        <div data-aos="zoom-in">
          <div className="border-conic-gradient p-6 text-center shadow-lg hover:bg-gray-800 hover:translate-y-[-10px] transition-transform duration-300">
            <h3 className="text-2xl font-semibold text-primary mb-4">Aylık</h3>
            <p className="text-xl text-gray-400 line-through">6000 TL</p>
            <p className="text-3xl font-bold text-white">3000 TL</p>
            <p className="text-sm text-gray-400 mb-4">
              Lansmana Özel %50 İndirim
            </p>
            {/*<button className="bg-primary text-black font-medium py-2 px-6 rounded-lg hover:bg-secondary transition duration-300 ease-in-out">*/}
            {/*  Satın Al*/}
            {/*</button>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
