import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { weeklyROIs } from "../profit-calculator/profit-calculator";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const FAKE_DATA = {
  labels: [
    "Hafta 1",
    "Hafta 2",
    "Hafta 3",
    "Hafta 4",
    "Hafta 5",
    "Hafta 6",
    "Hafta 7",
    "Hafta 8",
    "Hafta 9",
    "Hafta 10",
    "Hafta 11",
    "Hafta 12",
    "Hafta 13",
    "Hafta 14",
    "Hafta 15",
  ],
  datasets: [
    {
      fill: true,
      label: "Haftalık Yatırım Dönüş %",
      data: weeklyROIs.map((roi) => roi * 100),
      borderColor: "#22C55E",
      // backgroundColor: "rgba(134, 244, 152, 0.4)",
      borderWidth: 1,
    },
  ],
};
function LineGraph() {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
        labels: {
          color: "rgba(134, 244, 152, 0.4)", // Legend text colo
        },
      },
      title: {
        display: false,
        text: "ROI %",
        color: "#FFFFFF", // Title text color
      },
      tooltip: {
        backgroundColor: "#1F2937", // Tooltip background color
        titleColor: "#FFFFFF", // Tooltip title color
        bodyColor: "#FFFFFF", // Tooltip body color
      },
    },
    scales: {
      x: {
        // grid: {
        //   color: "rgba(255, 255, 255, 0.1)", // X-axis grid color
        // },
        ticks: {
          // color: "#FFFFFF", // X-axis tick color
          color: "rgba(134, 244, 152, 0.4)", // X-axis tick color
        },
      },
      y: {
        // grid: {
        //   color: "rgba(134, 244, 152, 0.4)", // Y-axis grid color
        // },
        ticks: {
          color: "rgba(134, 244, 152, 0.4)", // Y-axis tick color
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // Smoothing the line
      },
      point: {
        radius: 0, // Point size
        hitRadius: 20, // Increase hit radius for better hover effects
      },
    },
  };
  return (
    <div className="relative md:h-full">
      <Line options={options} data={FAKE_DATA} />
    </div>
  );
}
export default LineGraph;
