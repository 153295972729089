import nbaLogo from "../../assets/leagues/nba.png";
import nflLogo from "../../assets/leagues/nfl.png";
import eplLogo from "../../assets/leagues/epl.png";
import ncaafLogo from "../../assets/leagues/ncaa-f.png";
import ncaabLogo from "../../assets/leagues/ncaa-b.png";
import mmaLogo from "../../assets/leagues/mma.png";
import nhlLogo from "../../assets/leagues/nhl.png";
import shlLogo from "../../assets/leagues/shl.png";
import atpLogo from "../../assets/leagues/atp.png";
import giftbox from "../../assets/giftbox.svg";
import LineGraph from "../../components/charts/Line";
import CompoundProfitCalculator, {
  weeklyROIs,
} from "../../components/profit-calculator/profit-calculator";
import companyLogo from "../../assets/company-logo/company-logo-512.png";
import Features from "./Features";
import Footer from "../../components/footer/footer";
import Pricing from "../../components/pricing/pricing";
import CountDown from "../../components/count-down/count-down";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use CSS @import

// import bhaLogo from "../../assets/leagues/bha.png";
// import mlbLogo from "../../assets/leagues/mlb.png";

const sportsIcons = [
  { icon: nflLogo, size: "w-32 h-32" }, // checked
  { icon: eplLogo, size: "w-32 h-32" }, // checked
  { icon: ncaabLogo, size: "w-32 h-32" }, // checked
  { icon: ncaafLogo, size: "w-32 h-24" }, // checked
  { icon: mmaLogo, size: "w-32 h-24" }, // checked
  { icon: shlLogo, size: "w-32 h-12" }, // checked
  { icon: atpLogo, size: "w-32 h-12" }, // checked
  { icon: nhlLogo, size: "w-28 h-32" }, // checked
  { icon: nbaLogo, size: "w-14 h-32" }, // checked
  // { icon: mlbLogo, size: "w-32 h-16" }, // coming soon
  // { icon: bhaLogo, size: "w-32 h-32" }, // coming soon
];
function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);
  const averageROI =
    (weeklyROIs.reduce((acc, roi) => acc + roi, 0) / weeklyROIs.length) * 100;
  return (
    <div className="text-center sm:text-start p-4 md:p-8 text-white mt-12 xl2:mt-32">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col justify-between text-white">
          <div data-aos="zoom-out">
            <h1 className="font-bold mb-10 text-5xl md:text-6xl">
              Daha Akıllı Bahis Yapın,
              <br />
              <span className="bg-primary text-black px-2">
                Daha Büyük
                <br />
                <span className="px-2">Kazanın!</span>
              </span>
            </h1>
            <p className="text-xl mb-12">
              Spor bahis platformumuza katılın ve en son veriler, tahminler ve
              analizlerle bilinçli bahisler yaparak kazancınızı maksimize edin.
            </p>
          </div>
          <div className="sm:mt-8" data-aos="zoom-in">
            <button className="border-conic-gradient hover:text-black bg-black font-medium py-4 md:py-3 px-24 sm:px-16 hover:bg-primary shadow-lg transition duration-300 ease-in-out inline-flex items-center">
              <img src={companyLogo} alt="Company Logo" className="h-9 w-9" />
              <span>Katıl</span>
            </button>
          </div>

          <div
            data-aos="fade-up"
            className="mt-8 flex flex-col items-center text-center sm:text-start sm:items-start"
          >
            <CountDown />
            <span className="mt-4 text-sm">
              Süresince&nbsp;
              <span className="text-primary font-semibold">İlk 100&nbsp;</span>
              müşterimize
            </span>
            <div className="flex items-baseline iphone12Pro:flex-col iphone12Pro:items-center">
              <div className="flex">
                <img
                  src={giftbox}
                  alt="Giftbox"
                  className="h-6 w-6 mr-2 animate-pulseFade"
                />
                <span className="text-2xl font-semibold text-primary">
                  %50 indirim&nbsp;
                </span>
              </div>
              <span className="text-sm text-white">(97 kaldı)</span>
            </div>
          </div>
        </div>
        <div className="mt-16 sm:mt-0" data-aos="flip-right">
          <div className="md:hidden sm:text-center">
            <h1 className=" mb-4 text-5xl sm:text-6xl font-bold">Ligler</h1>
            <p className="sm:text-lg text-white">
              Şu anda 9 ligi destekliyoruz, ancak BHA ve MLB gibi liglerle
              genişlemeye çalışıyoruz.
            </p>
          </div>
          <div className="mt-8 sm:mt-0 grid grid-cols-2 sm:grid-cols-3 gap-4">
            {sportsIcons.map(({ icon, size }, index) => (
              <div key={index} className="flex justify-center items-center">
                <img
                  src={icon}
                  alt={`icon-${index}`}
                  className={`${size} mx-auto`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-16">
        <Features />
      </div>
      <div
        className="mt-16 sm:mt-24 xl2:mt-36 flex flex-col"
        data-aos="fade-up"
      >
        <h1 className="mb-4 text-4xl font-bold sm:text-6xl sm:text-start">
          Geçmis Performans
        </h1>
        <p className="mb-12 sm:text-lg text-white">
          Yapay zeka modellerimiz 9 ligde 15 hafta boyunca tahminlerde bulunarak
          haftada ortalama&nbsp;
          <span className="font-semibold text-xl">
            %{averageROI.toFixed(2)}
          </span>
          &nbsp; ROI elde etti. Modelimiz her tahmin için güven puanları ve
          tahminin gerekçesini de sunar. Instagram'daki sahte gurulara güvenmek
          zorunda değilsiniz. Tahminlerinizi verilerle destekleyin.
        </p>
      </div>
      <div
        className="grid grid-cols-1 md:grid-cols-[3fr_1fr] gap-4"
        data-aos="fade-up"
      >
        <div className="flex justify-center items-center">
          <div className="md:w-full">
            <LineGraph />
          </div>
        </div>
        <div
          className="sm:mt-0 mt-16 flex flex-col justify-end"
          data-aos="fade-right"
        >
          <CompoundProfitCalculator />
        </div>
      </div>
      <div className="mt-16 sm:mt-28">
        <Pricing />
      </div>
      <div className="mt-32 sm:mt-48">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
