import { useAuth0 } from "@auth0/auth0-react";

type UseAuth = {
  loginReturnTo?: string;
  signupReturnTo?: string;
};
export function useAuth({
  loginReturnTo = "/profile",
  signupReturnTo = "/profile",
}: UseAuth) {
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      prompt: "login",
      appState: {
        returnTo: loginReturnTo,
      },
    });
  };

  const handleSignUp = async () => {
    await loginWithRedirect({
      prompt: "login",
      screen_hint: "signup",
      appState: {
        returnTo: signupReturnTo,
      },
    });
  };

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return {
    handleLogin,
    handleSignUp,
    handleLogout,
    isAuthenticated,
    isLoading,
  };
}
