import React, { useEffect } from "react";
import { FaRobot, FaStar, FaChartLine } from "react-icons/fa";
import AOS from "aos";

function Features() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);
  return (
    <div className="text-start grid grid-cols-1 md:grid-cols-3 gap-8 sm:p-8 text-white cursor-default">
      <div data-aos="fade-up">
        <div className="border-2 border-white p-6 rounded-lg shadow-lg hover:bg-gray-800 ease-in-out hover:translate-y-[-10px] transition-transform duration-300">
          <div className="flex items-center mb-4">
            <FaRobot className="text-primary text-3xl mr-4" />
            <h3 className="text-2xl font-semibold">Yapay Zeka Tahminleri</h3>
          </div>
          <p>
            Bahisleriniz için en doğru ve güvenilir tahminleri sağlamak amacıyla
            en son yapay zeka algoritmalarını kullanıyoruz. Yapay zekamız geniş
            verileri analiz ederek size üstün içgörüler sunar.
          </p>
        </div>
      </div>
      <div data-aos="fade-up">
        <div className="border-2 border-white p-6 rounded-lg shadow-lg hover:bg-gray-800 ease-in-out hover:translate-y-[-10px] transition-transform duration-300">
          <div className="flex items-center mb-4">
            <FaStar className="text-primary text-3xl mr-4" />
            <h3 className="text-2xl font-semibold">Günün En İyi 5 Seçimi</h3>
          </div>
          <p>
            Yapay zeka analizlerimizden günlük olarak en iyi 5 maç tahminlerini
            alın. Bu seçimler, en iyi kararları vermenize ve kazancınızı
            maksimize etmenize yardımcı olacak şekilde özenle hazırlanır.
          </p>
        </div>
      </div>
      <div data-aos="fade-up">
        <div className="border-2 border-white p-6 rounded-lg shadow-lg hover:bg-gray-800 ease-in-out hover:translate-y-[-10px] transition-transform duration-300">
          <div className="flex items-center mb-4">
            <FaChartLine className="text-primary text-3xl mr-4" />
            <h3 className="text-2xl font-semibold">Kapsamlı Veri ve Analiz</h3>
          </div>
          <p>
            Takım ve oyuncu performansları, oranlar ve daha fazlası hakkında
            kapsamlı veri ve detaylı analizlere erişin. En son istatistiklerle
            bilinçli bahisler yaparak her zaman bir adım önde olun.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Features;
