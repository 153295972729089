import React from "react";
import logo from "../../assets/company-logo/company-logo-512.png";

export function NotFound() {
  return (
    <div className="not-found-page flex flex-col items-center justify-center min-h-screen">
      <img src={logo} alt="Company Logo" className="w-32 h-32 mb-8" />
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        404 - Page Not Found
      </h1>
      <p className="text-lg text-gray-600 mb-8">
        Sorry, the page you are looking for does not exist.
      </p>
      <a href="/" className="text-green-500 hover:underline">
        Go back to Home
      </a>
    </div>
  );
}

export default NotFound;
