import { useAuth } from "../../hooks/use-auth";
import discord from "../../assets/discord.png";
export const discordInvitationLink = "https://discord.gg/E5zF6Xvp";

function Footer() {
  const { handleSignUp } = useAuth({
    loginReturnTo: "/waitlist-success",
    signupReturnTo: "/waitlist-success",
  });

  return (
    <footer className="bg-black text-white py-10 border-t border-gray-600">
      <div className="container mx-auto px-4 md:px-8">
        <div className="text-center">
          <h2 className="text-xl font-bold">Zeki Bahis</h2>
          <p className="text-gray-400">
            Daha akıllı bahisler yapın, daha büyük kazanın.
          </p>
          <button
            onClick={handleSignUp}
            className="mt-4 bg-secondary text-black font-medium py-2 px-4 rounded-lg hover:bg-primary transform hover:scale-105 shadow-lg hover:shadow-xl transition duration-300 ease-in-outd"
          >
            Ön Kayıta Ücretsiz Katıl
          </button>
          <div className="mt-8 flex flex-col items-center">
            <p className="text-gray-600 text-sm">
              Copyright © 2024 - Tüm hakları saklıdır.
            </p>
            <img
              src={discord}
              alt="discord"
              className="h-16 w-16 animate-pulseFade hover:cursor-pointer hover:no-animate-pulseFade"
              onClick={() => window.open(`${discordInvitationLink}`, "_blank")}
            />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
