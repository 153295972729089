import { useState } from "react";

export const weeklyROIs = [
  0.2376, 0.05, 0.171, 0.127, 0.0476, 0.1051, 0.2331, 0.416, 0.1683, 0.6075,
  0.768, 1.24, 0.5532, 0.3467, 0.4986,
];

function CompoundProfitCalculator() {
  const [amount, setAmount] = useState<number | string>(1000);
  const [finalAmount, setFinalAmount] = useState<number | null>(null);

  const calculateFinalAmount = () => {
    if (typeof amount === "string") {
      return;
    }
    let result = amount as number;
    for (let roi of weeklyROIs) {
      result += result * roi;
    }
    setFinalAmount(result);
  };

  const formatAmount = (amount: number) => {
    return new Intl.NumberFormat("tr-TR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <div className="rounded shadow-md text-white flex flex-col items-center sm:items-start">
      <h2 className="text-5xl sm:text-2xl font-semibold mb-4 text-center sm:text-start">
        Potansiyel Kazancını Hesapla
      </h2>
      <div className="mb-4">
        <label htmlFor="amount" className="block mb-2">
          İlk Yatırım Tutarı
        </label>
        <div className="input-container">
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => {
              if (e.target.value === "") {
                return setAmount("");
              }
              setAmount(Number(e.target.value));
            }}
            className="currency-input hover:border-primary"
          />
          <div className="currency-symbol">
            TL
            {/*<img src={turkishFlag} alt="Turkish Flag" />*/}
          </div>
        </div>
      </div>
      <button
        onClick={calculateFinalAmount}
        className="sm:mb-2 bg-secondary text-black font-medium py-3 px-24 rounded-lg hover:bg-primary transform hover:scale-105 shadow-lg hover:shadow-xl transition duration-300 ease-in-out"
      >
        Hesapla
      </button>
      {finalAmount !== null && (
        <div className="mt-4">
          <p>{weeklyROIs.length} hafta sonundaki miktar:&nbsp;</p>
          <p className="font-bold text-4xl">
            {formatAmount(finalAmount)}&nbsp;TL
          </p>
        </div>
      )}
    </div>
  );
}

export default CompoundProfitCalculator;
