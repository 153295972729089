import useWindowSize from "react-use/lib/useWindowSize";
import ConfettiExplosion from "react-confetti-explosion";

const SHADES_OF_GREEN = [
  "#D9F99D",
  "#BEF264",
  "#A3E635",
  "#22C55E",
  "#84CC16",
  "#65A30D",
  "#16A34A",
  "#065F46",
];
export function AuthCallback() {
  const { height } = useWindowSize();
  const width = 4000; // I didn't use useWindowSize for width because Confetti keeps on firing from left side of the screen instead of center.

  return (
    <div className="flex flex-col text-center">
      <ConfettiExplosion
        width={width}
        height={height}
        colors={SHADES_OF_GREEN}
        particleCount={300}
      />
      <div className="flex flex-col text-center justify-center h-screen">
        <span className="font-extrabold text-6xl mb-8">Tebrikler!</span>
        <span>Değerli Kullanıcılarımız,</span>

        <span>
          Bekleme listemize kaydolduğunuz için teşekkür ederiz!
          <br /> Size en kısa sürede heyecan verici bir haber vermek için
          sabırsızlanıyoruz.
        </span>

        <span>Saygılarımızla – Zeki Bahis Ekibi</span>
      </div>
    </div>
  );
}

export default AuthCallback;
